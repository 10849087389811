import { useParams } from "react-router-dom";
import { JobStatus } from "../../../lib/interfaces/customers/jobs";

import { useUser } from "../../../hooks/use-user";
import { UserType } from "../../../lib/interfaces/user";
import { JobDetailsParent as CustomerJobDetails } from "../customer/[id]/job-details";
import { JobDetailsParent as InternalJobDetails } from "../internal/[id]/job-details";
import { useContext } from "react";
import { Context } from "../../../components/root/context";

const OrderDetailsParent = () => {
  const { user } = useUser();
  const { id } = useParams();

  if (!id) {
    return <div>Organisation not found</div>;
  }

  return <>{user?.userType === UserType.Customer ? <CustomerJobDetails id={id} /> : <InternalJobDetails id={id} />}</>;
};

export const StatusBar = ({ status }: { status: JobStatus }) => {
  const { isMobile } = useContext(Context);

  const getActiveIndex = () => {
    switch (status) {
      case JobStatus.New:
        return 0;
      case JobStatus.Active:
        return 1;
      case JobStatus.Feedback:
        return 2;
      case JobStatus.Done:
        return 3;
      default:
        return 0;
    }
  };

  const getActiveText = () => {
    switch (status) {
      case JobStatus.New:
        return "1 - Neu";
      case JobStatus.Active:
        return "2 - Aktiv";
      case JobStatus.Feedback:
        return "3 - Feedback";
      case JobStatus.Done:
        return "4 - Fertig";
      default:
        return "Neu";
    }
  };

  const activeStatusIndex = getActiveIndex();

  const StatusBarGradient = ({ isActive }: { isActive: boolean }) => {
    return <div className={`absolute top-4 left-5 ${isActive ? "custom-gradient" : "bg-white"} w-full h-2`}></div>;
  };

  const StatusBarNumber = ({ number, isActive }: { number: number; isActive: boolean }) => {
    return (
      <div
        className={`flex relative z-20 justify-center items-center text-xl ${
          isActive ? "text-white bg-[#12282A]" : "text-[#CCCCCC] bg-white"
        } w-10 h-10 rounded-full font-bold`}
      >
        {number}
      </div>
    );
  };

  const StatusBarText = ({
    text,
    isActive,
    customClass,
  }: {
    text: string;
    isActive: boolean;
    customClass?: string;
  }) => {
    return (
      <div
        className={`text-center ${isActive ? "" : "text-[#CCCCCC]"} text-sm font-semibold mt-2 ${customClass ?? ""}`}
      >
        {text}
      </div>
    );
  };

  const StatusBarItem = ({
    number,
    text,
    index,
    last,
    currentActiveIndex,
    customTextClass,
  }: {
    number: number;
    text: string;
    index: number;
    currentActiveIndex: number;
    last?: boolean;
    customTextClass?: string;
  }) => {
    const isGradientActive = index < currentActiveIndex;
    const isStatusNumberActive = index <= currentActiveIndex;
    const isTextActive = index === currentActiveIndex;

    return (
      <div className={last ? "" : "w-1/3 relative"}>
        {last ? <></> : <StatusBarGradient isActive={isGradientActive} />}
        <div className="w-fit">
          <StatusBarNumber number={number} isActive={isStatusNumberActive} />
          <StatusBarText text={text} isActive={isTextActive} customClass={customTextClass} />
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="flex">Status: {getActiveText()}</div>
      ) : (
        <div className="flex items-center">
          <StatusBarItem number={1} text="Neu" index={0} currentActiveIndex={activeStatusIndex} />
          <StatusBarItem number={2} text="Aktiv" index={1} currentActiveIndex={activeStatusIndex} />
          <StatusBarItem
            number={3}
            text="Feedback"
            index={2}
            currentActiveIndex={activeStatusIndex}
            customTextClass={"-ml-[10px]"}
          />
          <StatusBarItem number={4} text="Fertig" index={3} currentActiveIndex={activeStatusIndex} last />
        </div>
      )}
    </>
  );
};

export default OrderDetailsParent;
