import { OrganizationListEntityResponseDto, OrganizationListResponseDto } from "@/lib/interfaces/organisation";
import { Pagination } from "@/lib/interfaces/utils";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Clock3 } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, formatDateDetail, formatDateTime } from "../../../lib/hooks/utils";
import BankIcon from "../../../ui/components/icons/bank-icon";
import ContractIcon from "../../../ui/components/icons/contract-icon";
import RocketIcon from "../../../ui/components/icons/rocket-icon";
import StarIcon from "../../../ui/components/icons/star-icon";
import { Button } from "../../../ui/components/ui/button";
import Preloader from "../../../ui/components/ui/preloader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/ui/table";

interface DataProps {
  data: OrganizationListResponseDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pageCount: number;
  pagination: Pagination;
  isLoading: boolean;
  listEntities?: OrganizationListEntityResponseDto;
  // globalFilter: string;
  // setGlobalFilter: Dispatch<SetStateAction<string>>;
}

export const OrganisationDataTable: React.FC<DataProps> = ({
  data,
  setReload,
  setPagination,
  pageCount,
  pagination,
  isLoading,
  listEntities,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const columns: ColumnDef<OrganizationListResponseDto>[] = [
    {
      id: "picture",
      header: "",
      cell: ({ row }) => {
        return (
          <div className={`max-w-[54px] relative min-w-12 `}>
            <img
              src={
                row.original.profilePictureUrl ?? "/user-default.svg"
              }
              alt={row.original.name}
              width={54}
              height={54}
              className="rounded-full h-14 w-14 object-cover"
            />
          </div>
        );
      },
    },

    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div className="flex gap-2 items-center">
            <p
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              Unternehmen
              {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
            </p>
          </div>
        );
      },
      cell: ({ row }) => {
        const organisation = row.original;
        return (
          <div className="flex flex-col text-sm">
            <p className="font-bold">{capitalizeFirstLetter(organisation.name)}</p>
            <p className="text-[#888] font-medium">Kundennummer: {organisation.customerNumber}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer min-w-32"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Letzter Login
            {/* {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""} */}
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            <Clock3 size="18" />
            {formatDateTime(row.original.lastLogin)}
          </div>
        );
      },
    },
    {
      accessorKey: "customerNumber",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Mitarbeiter
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center text-sm">
            <div className="border border-[#D0D5DD] w-fit text-center rounded-2xl px-3 py-1 font-bold">
              <p>{row.original.assignedInternalUserFullName ?? "Nicht zugewiesen"}</p>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "belongsToStatus",
      header: "",
      cell: ({ row }) => {
        const status = listEntities?.status.find((s) => s.id === row.original.belongsToStatus);

        let icon;

        switch (status?.icon) {
          case "stars":
            icon = <StarIcon />;
            break;
          case "rocket":
            icon = <RocketIcon />;
            break;
          case "contract":
            icon = <ContractIcon />;
            break;
          case "bank":
            icon = <BankIcon />;
            break;
          default:
            icon = <ContractIcon />;
            break;
        }

        const colorStyle = status?.color
          ? {
              borderColor: status.color,
              color: status.color,
              backgroundColor: `${status.color}33`, // Hex with 20% opacity
            }
          : {};

        return (
          <div className="flex justify-end items-center text-sm">
            <div className="border w-fit rounded-2xl px-3 py-1 font-bold flex gap-2 items-center" style={colorStyle}>
              {icon}
              <p>
                {status ? (
                  <>
                    {status.needsStatusDate
                      ? `${status.value} - ${formatDateDetail(row.original.statusDate)}`
                      : status.value}
                  </>
                ) : (
                  "Status nicht gefunden"
                )}
              </p>
            </div>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    // globalFilterFn: globalFilterFunction,
    // onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 } },
    state: {
      pagination,
      sorting,
      rowSelection,
      // globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="rounded-md overflow-hidden">
        {isLoading ? (
          <Preloader />
        ) : (
          <Table className="border-separate border-spacing-y-4">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="text-[#D0D5DD] h-6">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                <>
                  {table.getRowModel().rows.length
                    ? table.getRowModel().rows.map((row, i) => (
                        <TableRow
                          key={row.id}
                          className={` bg-white hover:bg-white ${row.original.isOpenable ? "cursor-pointer" : ""}`}
                          onClick={() => {
                            if (row.original.isOpenable) {
                              navigate(`/organisations/${row.original._id}`);
                            }
                          }}
                        >
                          {row.getVisibleCells().map((cell, j) => (
                            <TableCell
                              key={cell.id}
                              className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${
                                j === 0 ? "rounded-l-[61px]" : ""
                              } ${j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""}`}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : " "}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Du hast noch keine Organisation angelegt
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="flex items-center justify-between py-4 my-2">
        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          zurück
        </Button>
        <span className="text-sm text-[#D0D5DD]">
          Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
        </span>

        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          nächste
        </Button>
      </div>
    </div>
  );
};
