import { LogOut, PlusIcon, Settings } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import { UserRole, UserType } from "../../lib/interfaces/user";
import AddIcon from "../../ui/components/icons/add-icon";
import FingerPrintIcon from "../../ui/components/icons/finger-print-icon";
import LightningIcon from "../../ui/components/icons/lightning-icon";
import NotificationsIcon from "../../ui/components/icons/notifications-icon";
import OrdersIcon from "../../ui/components/icons/orders-icon";
import SupportIcon from "../../ui/components/icons/support-icon";
import UserIcon from "../../ui/components/icons/user-icon";
import MyAvatar from "../../ui/components/ui/my-avatar";
import MessagesPopup from "../../ui/components/ui/notification-popup";
import { useContext } from "react";
import { Context } from "../root/context";

interface SidebarItemI {
  title: string;
  icon: JSX.Element;
  link: string;
  end?: boolean;
  isActive?: boolean;
}

export enum MessageStatusEnum {
  TEXT = "Text",
  ERROR = "Error",
  ROCKET = "Rocket",
}

export interface IMessage {
  _id: string;
  picture: string;
  opened: boolean;
  title: string;
  text: string;
  date: string;
  number: string;
  status: MessageStatusEnum;
}

const sidebarCreateOrder = {
  title: "Neuer Auftrag",
  icon: <AddIcon />,
  link: "/order/create",
};

const sidebarOrders = {
  title: "Auftrag ansehen",
  icon: <OrdersIcon />,
  link: "/",
};

const sidebarCustomers = {
  title: "Customers",
  icon: <UserIcon />,
  link: "/collaborators",
};

const sidebarNotifications = {
  title: "",
  icon: <NotificationsIcon />,
  link: "/notifications",
};

const sidebar7i7BestPractices = {
  title: "7i7 Leitfaden",
  icon: <LightningIcon />,
  link: "/7i7-best-practices",
};

const sidebarSettings = {
  title: "Settings",
  icon: <Settings />,
  link: "/settings",
  end: true,
};

const sidebarLogout = {
  title: "Hilfe",
  icon: <LogOut />,
  link: "/logout",
  end: true,
};

const sidebarSupport = {
  title: "Support",
  icon: <SupportIcon />,
  link: "/support",
  end: true,
};

const sidebarOrganisations = {
  title: "Organisations",
  icon: <FingerPrintIcon />,
  link: "/organisations",
};

const Sidebar = () => {
  const { user } = useUser();
  const { isMobile } = useContext(Context);

  let sidebarItems: SidebarItemI[] = [];

  if (user) {
    if (user.userType === UserType.Internal) {
      if (user.userRole === UserRole.Admin || user.userRole === UserRole.SuperAdmin) {
        sidebarItems.push(sidebarCreateOrder);
        sidebarItems.push(sidebarOrders);
        sidebarItems.push(sidebarNotifications);
        sidebarItems.push(sidebarCustomers);
        sidebarItems.push(sidebarOrganisations);
        // end
        sidebarItems.push(sidebarSettings);
        sidebarItems.push(sidebarLogout);
      } else {
        sidebarItems.push(sidebarOrders);
        sidebarItems.push(sidebarNotifications);
        sidebarItems.push(sidebarOrganisations);
        sidebarItems.push(sidebar7i7BestPractices);
        // end
        sidebarItems.push(sidebarSettings);
        sidebarItems.push(sidebarLogout);
      }
    } else {
      sidebarItems.push(sidebarCreateOrder);
      sidebarItems.push(sidebarOrders);
      sidebarItems.push(sidebarNotifications);
      if (user.userRole === UserRole.Admin) {
        sidebarItems.push(sidebarCustomers);
      }
      // end
      sidebarItems.push(sidebarSupport);
      sidebarItems.push(sidebarSettings);
      sidebarItems.push(sidebarLogout);
    }
  }

  const location = useLocation();

  return (
    <>
      {isMobile ? (
        <div className="bg-black p-2 flex items-center flex-col">
          <Link to="/">
            <img src="../logo.svg" alt="7i7 logo" width={60} height={60} />
          </Link>
          <Link to="/order/create">
            <div className="fixed bottom-6 rounded-full right-6 bg-black p-4 z-50 drop-shadow-2xl">
              <PlusIcon size={24} className="text-white" />
            </div>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col fixed min-h-[710px] z-20 items-center bg-[#000000] text-white w-28 h-[calc(100vh-40px)] my-5 rounded-r-[40px] pt-11 pb-9">
          <div className="w-[54px] h-[58px] relative">
            <Link to="/">
              <img src="../logo.svg" alt="7i7 logo" width={60} height={60} />
            </Link>
          </div>

          <nav className="flex flex-col gap-2 mt-12">
            {sidebarItems
              .filter((item) => !item.end)
              .map((item, index) =>
                item.link === "/notifications" ? (
                  <MessagesPopup key={item.link} />
                ) : (
                  <SidebarItem key={item.link} {...item} isActive={location.pathname === item.link} />
                )
              )}
          </nav>
          <nav className="flex flex-col gap-1 mt-auto mb-3">
            {sidebarItems
              .filter((item) => item.end)
              .map((item) => (
                <SidebarItem key={item.link} {...item} isActive={location.pathname === item.link} />
              ))}
          </nav>
          <MyAvatar />
        </div>
      )}
    </>
  );
};

const SidebarItem = ({ icon, link, isActive }: SidebarItemI) => {
  return (
    <Link
      // key={link}
      to={link}
      className={`rounded-full w-[60px] h-[60px] flex justify-center items-center bg-white hover:bg-opacity-10 ${
        isActive ? "bg-opacity-15" : "bg-opacity-0"
      }`}
    >
      {icon}
    </Link>
  );
};

export default Sidebar;
